<template>
  <div
    class="flex flex-col container mx-auto p-5 max-w-md"
    style="margin-top: 5%"
  >
    <h1 class="text-4xl font-semibold pb-3">Shutdown</h1>

    <div
      class="flex justify-center py-3 px-4 text-yellow-700 bg-yellow-100 rounded-lg"
    >
      <span>
        <p>
          This system is no longer accessible. Please go to
          <a class="underline" href="https://edu.steamplug.com">
            edu.steamplug.com
          </a>
          instead.
        </p>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import firebase from "firebase/app";
import "firebase/auth";
import { defineComponent, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const auth = firebase.auth();

    const form = reactive({
      email: "",
      password: "",
    });

    const errorMessage = ref("");

    function onSubmit() {
      errorMessage.value = "";

      if (form.email && form.password) {
        auth
          .signInWithEmailAndPassword(form.email, form.password)
          .then(() => {
            form.email = "";
            form.password = "";
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              errorMessage.value = "Incorrect username/password combination";
            } else if (err.code === "auth/wrong-password") {
              errorMessage.value = "Incorrect username/password combination";
            } else if (err.code === "auth/too-many-requests") {
              errorMessage.value =
                "Account temporarily disabled due to too many failed login attempts";
            }
          });
      }
    }

    function goToRedeemAccessCode() {
      router.push({ name: "RedeemAccessCode" });
    }

    return { ...toRefs(form), onSubmit, errorMessage, goToRedeemAccessCode };
  },
});
</script>
