
import firebase from "firebase/app";
import "firebase/auth";
import { defineComponent, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const auth = firebase.auth();

    const form = reactive({
      email: "",
      password: "",
    });

    const errorMessage = ref("");

    function onSubmit() {
      errorMessage.value = "";

      if (form.email && form.password) {
        auth
          .signInWithEmailAndPassword(form.email, form.password)
          .then(() => {
            form.email = "";
            form.password = "";
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              errorMessage.value = "Incorrect username/password combination";
            } else if (err.code === "auth/wrong-password") {
              errorMessage.value = "Incorrect username/password combination";
            } else if (err.code === "auth/too-many-requests") {
              errorMessage.value =
                "Account temporarily disabled due to too many failed login attempts";
            }
          });
      }
    }

    function goToRedeemAccessCode() {
      router.push({ name: "RedeemAccessCode" });
    }

    return { ...toRefs(form), onSubmit, errorMessage, goToRedeemAccessCode };
  },
});
